/* ::-webkit-scrollbar {
  display: none;
  width: 8px;
  height: 8px;
  overflow: overlay;
} */

/* remove video progress bar */
video::-webkit-media-controls-timeline {
  display: none;
}
video::-webkit-media-controls {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  margin: 0;
  font-family: "NotoSansKR", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  overflow: overlay;
}
body * {
  font: inherit;
}
img {
  -webkit-user-drag: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Twemoji Country Flags";
  unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067,
    U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
  src: url("./redwit-frontend/src/fonts/TwemojiCountryFlags.woff2")
    format("woff2");
}

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Bold"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Bold.woff2")
      format("woff2");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Medium"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Medium.woff2")
      format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Regular"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Regular.woff2")
      format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Light"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Light.woff2")
      format("woff2");
  font-weight: 400;
  font-display: swap;
}
